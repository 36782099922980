<template>
  <div class="more-info">
    <div class="cont" v-show="!control.gridShow">
      <van-cell name="有无网格" title="有无网格" :border="false" :title-class="'titleClass'" :value="show.hasGrid||'请选择'" @click="control.isGridShow = true" placeholder="请选择" is-link/>
      <van-popup v-model="control.isGridShow" position="bottom">
        <van-picker
            show-toolbar
            value-key="label"
            :columns="selectList.isGridList"
            @confirm="isGridfirm"
            @cancel="control.isGridShow = false"
        />
      </van-popup>
      <van-popup v-model="control.area" position="bottom"  >
        <van-picker
            title="请选择"
            show-toolbar
            :columns="areaList"
            value-key="label"
            @confirm="onConfirm"
            @cancel="onCancel"
        />
      </van-popup>
            <van-cell name="籍贯" title="籍贯" :border="false" :title-class="'titleClass'" :value="show.nativePlace||'请选择'" @click="control.area = !control.area" placeholder="请选择" is-link/>

<!--      <p @click="control.area = !control.area" :style="{color:control.area?'#387FF5' : '#666666' } ">-->
<!--                    {{ show.nativePlace }}-->
<!--                    <img :src="require(`@/assets/img/${control.area?'pull-down-select' :'pull-down'}.png`)" alt="" class="pull-icon">-->
<!--                  </p>-->
<!--      <van-cell name="网格" title="网格" :border="false" :title-class="'titleClass'" :value="show.gridName||'请选择'" @click="gridsFn" placeholder="请选择" is-link/>-->


      <!--    <van-cell title="所属网格" :border="false">-->
      <!--      <span style="color: #387FF5">{{ show.gridName }}</span>-->
      <!--    </van-cell>-->
      <!--    <van-grid :border="false" :column-num="4">-->
      <!--      <van-grid-item v-for="item in selectList.gridsList" :key="item.value">-->
      <!--        <van-tag size="large" @click="gridsFn(item)" color="#F8F8F8" :class="{'border-selected': item.label == show.gridName}"-->
      <!--          :text-color="item.label == show.gridName?'#387FF5':'#000'">{{ item.label }}-->
      <!--        </van-tag>-->
      <!--      </van-grid-item>-->
      <!--    </van-grid>-->

      <van-cell title="证件类型" :border="false" :title-class="'titleClass'">
<!--        <span style="color: #387FF5">{{ show.idNumberType }}</span>-->
      </van-cell>
      <van-grid :border="false" >
        <van-grid-item v-for="item in selectList.idNumberTypeList" :key="item.value">
          <van-tag size="large" @click="idNumberTypeFn(item)" :color="item.label == show.idNumberType?'#4581F8':'#fff'" :class="{'border-selected': item.label == show.idNumberType}"
                   :text-color="item.label == show.idNumberType?'#fff':'#666'">{{ item.label }}
          </van-tag>
        </van-grid-item>
      </van-grid>
      <van-cell title="出生迁入" :border="false" :title-class="'titleClass'">
      </van-cell>
      <div class="dateBar" style="height: 2.6rem;padding-right: 0.25rem;" >
        <van-row>
          <van-col span="6">出生日期</van-col>
          <van-col span="6" @click="control.beginBirthday = !control.beginBirthday">
            <span>{{ dataForm.beginBirthday == '' ? '最早' : dataForm.beginBirthday }}</span></van-col>
          <van-col span="1"><span>-</span></van-col>
          <van-col span="6" @click="control.endBirthday = !control.endBirthday">
            <span>{{ dataForm.endBirthday == '' ? '至今' : dataForm.endBirthday }}</span></van-col>
<!--          <van-col span="2" class="reset" @click="birthdayReset"><span>重置</span></van-col>-->
<!--          <van-col span="3" class="confirm" @click="birthdayTimeclick"><span>确定</span></van-col>-->
        </van-row>
        <van-row>
          <van-col span="6">迁入日期</van-col>
          <van-col span="6" @click="control.beginMoveInDate = !control.beginMoveInDate ">
            <span>{{ dataForm.beginMoveInDate == '' ? '最早' : dataForm.beginMoveInDate }}</span></van-col>
          <van-col span="1"><span>-</span></van-col>
          <van-col span="6" @click="control.endMoveInDate = !control.endMoveInDate ">
            <span>{{ dataForm.endMoveInDate == '' ? '至今' : dataForm.endMoveInDate }}</span></van-col>
<!--          <van-col span="2" class="reset" @click="moveInReset"><span>重置</span></van-col>-->
<!--          <van-col span="3" class="confirm" @click="moveIntimeclick"><span>确定</span></van-col>-->
        </van-row>
      </div>

      <van-popup v-model="control.beginBirthday" position="bottom" >
        <van-datetime-picker @cancel="control.beginBirthday = false" @confirm="beginBirthdayChange" type="date" title="选择年月日" :min-date="defaultDate" :max-date="new Date()"/>
      </van-popup>
      <van-popup v-model="control.endBirthday" position="bottom" >
        <van-datetime-picker @cancel="control.endBirthday = false" @confirm="endBirthdayChange" type="date" title="选择年月日" :min-date="defaultDate" :max-date="new Date()"/>
      </van-popup>
      <van-popup v-model="control.beginMoveInDate" position="bottom" >
        <van-datetime-picker @cancel="control.beginMoveInDate = false" @confirm="beginMoveInDateChange" type="date" title="选择年月日" :min-date="defaultDate" :max-date="new Date()"/>
      </van-popup>
      <van-popup v-model="control.endMoveInDate" position="bottom" >
        <van-datetime-picker @cancel="control.endMoveInDate = false" @confirm="endMoveInDateChange" type="date" title="选择年月日" :min-date="defaultDate" :max-date="new Date()"/>
      </van-popup>
      <div style="display: flex;width: 100%">
      <van-button type="info"  style="white-space:nowrap;" @click="Nation">{{nationalState===true?'正选':'反选'}}</van-button>
      <van-cell name="民族" title="民族" :border="false" :value="show.nation||'请选择'" @click="control.nation = true" placeholder="请选择民族" is-link/>
      </div>
      <van-cell title="有无房屋" :border="false" class="crossGrid">
        <van-grid :border="false" :column-num="5">
          <van-grid-item v-for="item in selectList.haveHouseList" :key="item.value">
            <van-tag size="large" @click="haveHouseFn(item)" :color="item.label == show.haveHouse?'#4581F8':'#fff'" :class="{'border-selected': item.label == show.haveHouse}"
                     :text-color="item.label == show.haveHouse?'#fff':'#666'">{{ item.label }}
            </van-tag>
          </van-grid-item>
        </van-grid>
<!--        <span style="color: #387FF5">{{ show.haveHouse }}</span>-->
      </van-cell>

      <van-cell title="年龄" :border="false">
        <van-row>
          <van-col span="4">
            <input v-model="dataForm.beginAge" placeholder="" class="ageInput">
          </van-col>
          <van-col span="3">
            <div style="width:15px;height:1px;background-color:#CCCCCC;display:inline-block;margin: 0 10px;"></div>
          </van-col>
          <van-col span="4">
            <input v-model="dataForm.endAge" placeholder="" class="ageInput">
          </van-col>
        </van-row>
      </van-cell>

      <van-cell title="性别" :border="false" class="crossGrid sex">
        <van-grid :border="false">
          <van-grid-item v-for="item in selectList.sexList" :key="item.value">
            <van-tag size="large" @click="sexFn(item)" :color="item.label == show.sex?'#4581F8':'#fff'" :class="{'border-selected': item.label == show.sex}"
                     :text-color="item.label == show.sex?'#fff':'#666'">{{ item.label }}
            </van-tag>
          </van-grid-item>
        </van-grid>
<!--        <span style="color: #387FF5">{{ show.sex }}</span>-->
      </van-cell>

      <van-cell title="身份证校验" :border="false" class="crossGrid">
        <van-grid :border="false" :column-num="2">
          <van-grid-item v-for="item in selectList.rightIdNumberList" :key="item.value">
            <van-tag size="large" @click="rightIdNumberFn(item)" :color="item.label == show.rightIdNumber?'#4581F8':'#fff'" :class="{'border-selected': item.label == show.rightIdNumber}"
                     :text-color="item.label == show.rightIdNumber?'#fff':'#666'">{{ item.label }}
            </van-tag>
          </van-grid-item>
        </van-grid>
<!--        <span style="color: #387FF5">{{ show.rightIdNumber }}</span>-->
      </van-cell>

      <van-cell name="与房主关系" title="与房主关系" :border="false" :value="show.relationship||'请选择'" @click="control.relationship = true" placeholder="请选择与户主关系" is-link/>
      <van-popup v-model="control.relationship" position="bottom">
        <van-picker
            show-toolbar
            value-key="label"
            :columns="selectList.relationshipList"
            @confirm="relationshipfirm"
            @cancel="control.relationship = false"
        />
      </van-popup>

      <van-cell title="仅显示待核查" :border="false" class="crossGrid">
        <van-grid :border="false" :column-num="4">
          <van-grid-item v-for="item in selectList.isNotCheckedList" :key="item.value">
            <van-tag size="large" @click="isNotCheckedFn(item)" :color="item.label == show.allCheck?'#4581F8':'#fff'" :class="{'border-selected': item.label == show.allCheck}"
                     :text-color="item.label == show.allCheck?'#fff':'#666'">{{ item.label }}
            </van-tag>
          </van-grid-item>
        </van-grid>
<!--        <span style="color: #387FF5">{{ show.isNotChecked }}</span>-->
      </van-cell>

      <van-cell name="现居地省市区" title="现居地省市区" :border="false" :value="show.nowAddressPcc||'请选择'"
                @click="control.nowAddressPcc = true" placeholder="请选择现居地省市区" is-link/>

      <van-popup v-model="control.nowAddressPcc" position="bottom"
                 @click-overlay="nowAddressPccOverlay">
        <van-cascader
            title="请选择"
            value="value"
            :field-names="{ text: 'label', value: 'value', children: 'children' }"
            :options="cityData"
            active-color="#1989fa"
            @close="nowAddressPccClose"
            @change="nowAddressPccFinish"
        />
      </van-popup>

      <van-cell name="户籍地省市区" title="户籍地省市区" :border="false" :value="show.regAddressPcc||'请选择'"
                @click="control.regAddressPcc = true" placeholder="请选择户籍地省市区" is-link/>

      <van-popup v-model="control.regAddressPcc" position="bottom"
                 @click-overlay="regAddressPccPccOverlay">
        <van-cascader
            title="请选择"
            value="value"
            :field-names="{ text: 'label', value: 'value', children: 'children' }"
            :options="cityData"
            active-color="#1989fa"
            @close="regAddressPccPccClose"
            @change="regAddressPccFinish"
        />
      </van-popup>
      <div class="select-button">
        <van-row gutter="20">
          <van-col :span="8"><van-button color="#ccc" block @click="reset">重置</van-button></van-col>
          <van-col :span="16"><van-button type="info" block @click="comfirm">确认</van-button></van-col>
        </van-row>
      </div>
    </div>
    <nation-select v-if="control.nation" @setNation="setNation"></nation-select>

    <grid-select v-show="control.gridShow" :list="selectList.gridsList" @selectLabel="selectGrid" ref="gridSelect"></grid-select>
  </div>
</template>

<script>
import {getVirtualDict,getDictTree} from "@/utils/common"
import NationSelect from '@/components/nation/nation'
import GridSelect from './grid-select.vue'
import {formatterDate} from "@/utils/utils"
import cityData from "../../utils/data"

export default {
  components: {
    NationSelect,
    GridSelect
  },
  props: ['community'],
  data () {
    return {
      areaList:
          [{value: "11", label: '北京'}, {value: "12", label: '天津', status: 0}, {value: '13', label: '河北'}, {
            value: '14',
            label: '山西'
          }, {value: '15', label: '内蒙古'},
            {value: '21', label: '辽宁'}, {value: '22', label: '吉林'}, {value: '23', label: '黑龙江'}, {
            value: '31',
            label: '上海'
          }, {value: '32', label: '江苏'}, {value: '33', label: '浙江'},
            {value: '34', label: '安徽'}, {value: '35', label: '福建'}, {value: '36', label: '江西'}, {
            value: '37',
            label: '山东'
          }, {value: '41', label: '河南'}, {value: '42', label: '湖北'},
            {value: '43', label: '湖南'}, {value: '44', label: '广东'}, {value: '45', label: '广西'}, {
            value: '46',
            label: '海南'
          }, {value: '50', label: '重庆'}, {value: '51', label: '四川'},
            {value: '52', label: '贵州'}, {value: '53', label: '云南'}, {value: '54', label: '西藏'}, {
            value: '61',
            label: '陕西'
          }, {value: '62', label: '甘肃'}, {value: '63', label: '青海'},
            {value: '64', label: '宁夏'}, {value: '65', label: '新疆'}, {value: '71', label: '台湾'}, {
            value: '81',
            label: '香港'
          }, {value: '82', label: '澳门'}, {value: '91', label: '国外'}],
      nationalState:true,
      cityData: [],
      defaultDate: new Date(1900, 0, 1),
      control: {
        area:false,
        nation: false,
        relationship: false,
        beginBirthday: false,
        endBirthday: false,
        beginMoveInDate: false,
        endMoveInDate: false,
        gridShow: false,
        isGridShow: false,
        nowAddressPcc: false,
        regAddressPcc: false
      },
      selectList: {
        gridsList: [],
        idNumberTypeList: [],
        registryTypeList: [],
        nationList: [],
        haveHouseList: [{
          label: '有',
          value: '1',
        }, {
          label: '无',
          value: '0',
        }],
        sexList: [{
          label: '男',
          value: '1',
        }, {
          label: '女',
          value: '2',
        }, {
          label: '所有',
          value: '',
        }],
        rightIdNumberList: [{
          label: '错误身份证',
          value: "0",
        }, {
          label: '正确身份证',
          value: "1",
        }],
        relationshipList: [],
        isNotCheckedList: [{
          label: '是',
          value: "0",
        }, {
          label: '否',
          value: "1",
        }],
        isGridList: [{
          label: '有',
          value: '1',
        }, {
          label: '没有',
          value: '0',
        }],
      },
      show: {
        nativePlace:'',
        gridName: '',
        idNumberType: '',
        registryType: '',
        nation: '',
        haveHouse: '',
        sex: '',
        rightIdNumber: '',
        relationship: '',
        allCheck: '',
        hasGrid: '',
        nowAddressPcc: '',
        regAddressPcc: ''
      },
      dataForm: {
        nativePlace:'',
        nativePlaceStr:'',
        nationCondition:1,
        gridId: '',
        miniGridId: '',
        idNumberType: '',
        registryType: '',
        nation: '',
        haveHouse: '',
        sex: '',
        rightIdNumber: '',
        relationship: '',
        allCheck: '',
        beginAge: '',
        endAge: '',
        beginBirthday: "",
        endBirthday: "",
        beginMoveInDate: "",
        endMoveInDate: "",
        hasGrid: '',
        nowAddressPcc: '',
        regAddressPcc: ''
      }
    }
  },
  watch: {
    community: {
      handler (val) {
        this.dataForm.gridId = ''
        this.dataForm.miniGridId = ''
        this.getGridList()
      }
    }
  },
  created () {
    this.control.gridShow = false
    this.getGridList()
    // this.getRegistryType()
    getVirtualDict ("idNumberType", (virtualDictList) => {
      this.selectList.idNumberTypeList = virtualDictList
    })
    getDictTree ({code: 'nationAddress'}, (res) => {
      this.cityData = res
    })
    this.$http({
      url: this.$http.adornUrl(`/wxapp/sys/dict/virtual/relationships`),
      method: 'get',
      params: this.$http.adornParams()
    }).then(({data}) => {
      if (data.code == 0) {
        data.relationships.map((r) => {
          r.value = r.value + ""
        })
        this.selectList.relationshipList = data.relationships
      }
    })
  },
  methods: {
    onConfirm(e, index) {
      this.dataForm.nativePlace = e.value
      this.dataForm.nativePlaceStr=e.label
      this.show.nativePlace = e.label
      this.control.area = false
    },
    onCancel() {
      this.show.nativePlace = ''
      this.dataForm.nativePlace = ""
      this.dataForm.nativePlaceStr=""
      this.control.area = false
    },
    nowAddressPccOverlay() {

    },
    Nation(){
      this.nationalState=!this.nationalState
      if(this.nationalState){
       this.dataForm.nationCondition=1
      }
      else{
        this.dataForm.nationCondition=2
      }
    },
    nowAddressPccClose(e) {
      this.control.nowAddressPcc = false
    },
    nowAddressPccFinish(e) {
      this.dataForm.nowAddressPcc = e.selectedOptions[e.selectedOptions.length - 1].label
      this.show.nowAddressPcc = e.selectedOptions.map(item => item.label).join('/')
    },
    regAddressPccPccOverlay() {

    },
    regAddressPccPccClose(e) {
      this.control.regAddressPcc = false
    },
    regAddressPccFinish(e) {
      this.dataForm.regAddressPcc = e.selectedOptions[e.selectedOptions.length - 1].label
      this.show.regAddressPcc = e.selectedOptions.map(item => item.label).join('/')
    },
    setNation(value) {
      if (value) {
        this.dataForm.nation = value.value
        this.show.nation = value.label
      }
      this.control.nation = false
    },
    getGridList () {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/grid/info/gridAndMini`),
        method: 'get',
        params: this.$http.adornParams({
          community: this.community || this.$globalData.userInfo.orgId
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.selectList.gridsList = data.treeVOS
        }
      })
    },
    // getGridList () {
    //   this.$http({
    //     url: this.$http.adornUrl(`/wxapp/grid/info/listByUser`),
    //     method: 'get',
    //     params: this.$http.adornParams({
    //       community: this.$globalData.userInfo.orgId
    //     })
    //   }).then(({data}) => {
    //     if (data.code == 0) {
    //       this.selectList.gridsList = data.grids
    //     }
    //   })
    // },
    getRegistryType () {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/sys/dict/virtual/registryType`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({data}) => {
        if (data.code == 0) {
          data.registryTypes.map((type) => {
            type.value = type.value + ""
          })
          this.selectList.registryTypeList = data.registryTypes
        }
      })
    },
    onPullDownRefresh(e) {
      this.getDataList()
    },
    isGridfirm (n) {
      this.show.hasGrid = n.label
      this.dataForm.hasGrid = n.value
      this.control.isGridShow = false
    },
    // 网格选择
    selectGrid (e) {
      this.dataForm.gridId = ''
      this.dataForm.miniGridId = ''
      const {selectList, labelCondition, depth} = e
      if (depth == 1) {
        this.dataForm.gridId = selectList
      } else {
        this.dataForm.miniGridId = selectList
      }
      if (labelCondition && labelCondition.length > 0) {
        this.show.gridName = labelCondition ? labelCondition[0].label : ''
      }
      this.control.gridShow = false
    },
    gridsFn() {
      this.control.gridShow = true
      // this.show.gridName = n.label
      // this.dataForm.gridId = n.value
    },
    idNumberTypeFn (n) {
      this.show.idNumberType = n.label
      this.dataForm.idNumberType = n.value
    },
    registryFn(n) {
      this.show.registryType = n.label
      this.dataForm.registryType = n.value
    },
    relationshipfirm (n) {
      this.control.relationship = false
      this.show.relationship = n.label
      this.dataForm.relationship = n.value
    },
    sexFn(n) {
      this.show.sex = n.label
      this.dataForm.sex = n.value
    },
    haveHouseFn(n) {
      this.show.haveHouse = n.label
      this.dataForm.haveHouse = n.value
    },
    rightIdNumberFn(n) {
      this.show.rightIdNumber = n.label
      this.dataForm.rightIdNumber = n.value
    },
    isNotCheckedFn (n) {
      this.show.allCheck = n.label
      this.dataForm.allCheck = n.value
    },
    birthdayReset: function (e) {
      that.dataForm.beginBirthday = '';
      that.dataForm.endBirthday = '';
    },
    //出生日期时间筛选中点击确定
    birthdayTimeclick: function (e) {
      this.onPullDownRefresh()
    },
    //迁入时间重置按钮点击
    moveInReset: function (e) {
      that.dataForm.beginMoveInDate = '';
      that.dataForm.endMoveInDate = '';
    },
    //嵌入时间筛选中点击确定
    moveIntimeclick: function (e) {
      that.onPullDownRefresh()
    },
    beginBirthdayChange(value) {
      let d = new Date(this.dataForm.endBirthday)
      if (this.dataForm.endBirthday != '' && d.getTime(d) < value.getTime(value)) {
        return this.$toast.fail('请选择正确的范围')
      }
      this.dataForm.beginBirthday = formatterDate(value)
      this.control.beginBirthday = false

    },
    endBirthdayChange(value) {
      let d = new Date(this.dataForm.beginBirthday)
      if (this.dataForm.beginBirthday != '' && d.getTime(d) > value.getTime(value)) {
        return this.$toast.fail('请选择正确的范围')
      }
      this.dataForm.endBirthday = formatterDate(value)
      this.control.endBirthday = false
    },
    beginMoveInDateChange(value) {
      let d = new Date(this.dataForm.endMoveInDate)
      if (this.dataForm.endMoveInDate != '' && d.getTime(d) < value.getTime(value)) {
        return this.$toast.fail('请选择正确的范围')
      }
      this.dataForm.beginMoveInDate = formatterDate(value)
      this.control.beginMoveInDate = false
    },
    endMoveInDateChange(value) {
      let d = new Date(this.dataForm.beginMoveInDate)
      if (this.dataForm.beginMoveInDate != '' && d.getTime(d) > value.getTime(value)) {
        return this.$toast.fail('请选择正确的范围')
      }
      this.dataForm.endMoveInDate = formatterDate(value)
      this.control.endMoveInDate = false
    },
    reset () {
      this.show = {
       nativePlace: '',
        gridName: '',
        idNumberType: '',
        registryType: '',
        nation: '',
        haveHouse: '',
        sex: '',
        rightIdNumber: '',
        relationship: '',
        allCheck: '',
        hasGrid: ''
      },
      this.dataForm = {
        nativePlaceStr:'',
        nativePlace:'',
        gridId: '',
        miniGridId: '',
        idNumberType: '',
        registryType: '',
        nation: '',
        haveHouse: '',
        sex: '',
        rightIdNumber: '',
        relationship: '',
        allCheck: '',
        beginAge: '',
        endAge: '',
        beginBirthday: "",
        endBirthday: "",
        beginMoveInDate: "",
        endMoveInDate: "",
        hasGrid: '',
        nationCondition:1,
      }
    },
    comfirm () {
      this.$emit('selectMore', this.dataForm)
    }
  }
}
</script>
<style scoped lang="scss">
.van-cell__title > span {
  font-size: 28px !important;
  color: #333 !important;
}
.van-cell{
  //height: 88px;
  //line-height: 88px;
  .van-field__label {
    width: unset !important;
  }
  .van-field__control {
    text-align: left !important;
    font-size: 34px !important;
    &::placeholder {
      color: #999 !important;
    }
  }
  ::v-deep .van-cell__title {
    flex: unset !important;
    font-size: 26px;
    color: #666666;
    margin-right: 24px;
  }
  ::v-deep .van-cell__value {
    font-size: 28px !important;
    text-align: left;
  }
}
.more-info {
  .van-grid {
    ::v-deep .border-selected {
      background: #4581F8;
    }
  }
  .van-tag {
    border: 2px solid #EEEEEE;
  }
  .dateBar {
    color: #666666;
    .reset,.confirm {
      color: #4581F8;
    }
  }
}
.titleClass {
  font-size: 28px !important;
  color: #333333 !important;
}
.crossGrid {
  ::v-deep .van-cell__title {
    line-height: 66px;
    margin-right: 0 !important;
  }
  ::v-deep .van-tag {
    width: unset !important;
    padding: 8px 20px !important;
  }
}
.sex {
  ::v-deep .van-grid-item {
    flex-basis: 4% !important;
    &:last-child {
      flex-basis: 20% !important;
    }
  }
}
</style>
<style scoped>
.more-info {
  padding-bottom: 168px;
  background-color: #fff;
}
.more-info >>> .van-grid {
  padding: 0 20px;
  background-color: #fff;
}
.more-info >>> .van-grid-item__content {
  padding: 8px 10px;
  border-radius: 4px;
}
.more-info >>> .van-tag {
  width: 100%;
  justify-content: center;
  padding: 20px 0;
  font-size: 26px;
}
.border-selected {
  border: 1px solid #387FF5;
}
.select-button {
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 40px;
  background-color: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .08);
}
.ageInput {
  width: 100%;
  height: 60px;
  border: 1px solid #D9D9D9;
  background-color: #fff;
  position: relative;
}
.divider {
  height: 1px;
  background-color: #F4F4F4;
  margin: 5px 30px;
}
</style>
